/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "44bits 팟캐스트 156번째 로그에서는 치즈님과 CLOVA FaceSign, Stacked Changes 코드 리뷰 방법에 대해서 이야기를 나누었습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://deview.kr/2021/sessions/522"
  }, "DEVIEW 2021 :: 마스크 쓰고도 나를 찾는 얼굴 인식! FaceSign 네이버 신사옥 입성기")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=WG7JHLfClEo&t=198s"
  }, "네이버는 왜 제2사옥 1784✨를 지었을까요? - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://infcon.day/speaker/%ec%84%9c%ec%a7%80%ec%97%b0-%ec%bd%94%eb%93%9c%eb%a6%ac%eb%b7%b0-%ea%b9%83%ed%97%99/"
  }, "코드 리뷰의 또 다른 접근 방법: Pull Requests vs. Stacked Changes - 서지연 - INFCON 2022")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.phacility.com/phabricator/"
  }, "Phabricator")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://graphite.dev/"
  }, "Graphite — Modern code review for fast-moving teams")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
